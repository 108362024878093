import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Link as RouterLink } from 'react-router-dom'
import { oemid } from '../token'
import { SelectChangeEvent } from '@mui/material/Select'
import { VehicleSegment } from '../vehiclesegment/vehiclesegment'

export interface Vehiclemodel {
  id: number
  name: string
  is_active: boolean
  year: number
  vehicleSegment: VehicleSegment
  subOem: number
}

export const Vehiclemodel: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [vehiclemodel, setVehiclemodel] = useState<Vehiclemodel[]>([])
  const [_vehicleSegments, setVehicleSegments] = useState<string[]>([])
  const [selectedSegment, setSelectedSegment] = useState<string>('')

  useEffect(() => {
    updateContext({ pageTitle: 'Vehicle Model' })
    fetchVehicleSegments()
    fetchVehiclemodel()
  }, [updateContext])

  const fetchVehicleSegments = async () => {
    try {
      const token = localStorage.getItem('token')
      if (!token) return

      const VehicleSegmentsResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oem/${oemid}/subOem/4/vehiclesegment`,
        {
          headers: {
            'x-api-key': token,
          },
        },
      )

      if (!VehicleSegmentsResponse.ok) {
        throw new Error('Failed to fetch vehicle segments')
      }

      // const segmentsData = await segmentsResponse.json()
      // const segments = Array.isArray(segmentsData) ? segmentsData : [segmentsData]
      const jsonData = await VehicleSegmentsResponse.json()
      const VehicleSegmentsArray = Array.isArray(jsonData) ? jsonData : [jsonData]
      setVehicleSegments(VehicleSegmentsArray)
    } catch (error) {
      console.error('Error fetching vehicle segments:', error)
    }
  }

  const fetchVehiclemodel = async () => {
    try {
      const token: string | null = localStorage.getItem('token')

      if (!token) {
        return
      }

      const vehiclemodelResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oem/${oemid}/subOem/4/vehiclesegment/1/vehicleModel`,
        {
          headers: {
            'x-api-key': token,
          },
        },
      )

      const jsonData = await vehiclemodelResponse.json()
      const vehiclemodelArray = Array.isArray(jsonData) ? jsonData : [jsonData]
      setVehiclemodel(vehiclemodelArray)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleSegmentChange = (event: SelectChangeEvent<string>) => {
    setSelectedSegment(event.target.value)
  }

  const VehicleModel = selectedSegment
    ? vehiclemodel.filter((vehicle) => vehicle.vehicleSegment.name === selectedSegment)
    : vehiclemodel
  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Vehicle Model
    </Typography>,
    <Typography
      key='2'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Add Vehicle Model
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id='segment-select-label'>Select Vehicle Segment</InputLabel>
        <Select
          labelId='segment-select-label'
          id='segment-select'
          value={selectedSegment}
          onChange={handleSegmentChange}
        >
          <MenuItem value=''>
            <em>All</em>
          </MenuItem>
          {/* {vehicleSegments.map((segment) => (
            <MenuItem key={segment.id} value={segment.name}>
              {segment.name}
            </MenuItem>
          ))} */}
        </Select>
      </FormControl>
      <Avatar
        component={Link}
        to='addvehiclemodel'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Created-On</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Vehicle Segment</TableCell>
              <TableCell>Is Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {VehicleModel.map((vehicle) => (
              <TableRow key={vehicle.id}>
                <TableCell>{vehicle.id}</TableCell>
                <TableCell>{vehicle.name}</TableCell>
                <TableCell>{vehicle.year}</TableCell>
                <TableCell>{vehicle.vehicleSegment.name}</TableCell>
                <TableCell>
                  <FormControlLabel
                    value='start'
                    control={<Checkbox />}
                    label=''
                    labelPlacement='start'
                  />
                </TableCell>
                <TableCell>
                  <Button component={Link} to='updatevehiclemodel'>
                    <EditIcon />
                  </Button>
                  <Button component={Link} to='addvehiclemodel'>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
export default Vehiclemodel
