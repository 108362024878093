import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useDashboardContext } from '../sideDrawer'
import { Link as RouterLink } from 'react-router-dom'
import { decodedPermissions } from '../token'

export interface Oem {
  id: number
  oem_name: string
  logo_url: string
  diads: number
  status: boolean
}

export const Oem: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [oems, setOems] = useState<Oem[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'OEM' })

    const fetchOems = async () => {
      try {
        const token: string | null = localStorage.getItem('token')

        if (!token) {
          return
        }

        const oemsResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems`, {
          headers: {
            'x-api-key': token,
          },
        })

        const jsonData = await oemsResponse.json()
        const oemArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setOems(oemArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchOems()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const token: string | null = localStorage.getItem('token')
      if (!token) {
        return
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/${id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': token,
        },
      })

      if (response.ok) {
        setOems((prevOems) => prevOems.filter((oem) => oem.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }

  const getStatusText = (status: boolean) => {
    return status ? 'Active' : 'Inactive'
  }

  const getStatusColor = (status: boolean) => {
    return status ? green[500] : red[500]
  }

  const getStatusBackgroundColor = (status: boolean) => {
    return status ? green[100] : red[100]
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      OEM
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      {decodedPermissions.includes('manageOEM') && (
        <Avatar
          component={Link}
          to='addoem'
          sx={{
            bgcolor: green[500],
            float: 'right',
            marginRight: 5,
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <AddIcon />
        </Avatar>
      )}
      <TableContainer component={Paper}>
        <Table sx={{ marginTop: '12px' }}>
          <TableHead
            sx={{
              paddingTop: '1px',
              borderWidth: '10px',
              borderBottom: 'solid',
              borderColor: 'red',
              fontSize: '13px',
            }}
          >
            <TableRow>
              <TableCell sx={{ fontSize: '18px' }}>Name</TableCell>
              <TableCell sx={{ fontSize: '18px' }}>Logo URL</TableCell>
              <TableCell sx={{ fontSize: '18px' }}>Diads</TableCell>
              <TableCell sx={{ fontSize: '18px' }}>Status</TableCell>

              {decodedPermissions.includes('manageOem') && (
                <TableCell sx={{ borderColor: 'red' }}>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {oems.map((oem) => (
              <TableRow key={oem.id}>
                <TableCell>{oem.oem_name}</TableCell>
                <TableCell>{oem.logo_url}</TableCell>
                <TableCell>{oem.diads}</TableCell>
                <TableCell>
                  <Typography
                    style={{
                      color: getStatusColor(oem.status),
                      backgroundColor: getStatusBackgroundColor(oem.status),
                      padding: '5px 10px',
                      borderRadius: '5px',
                    }}
                  >
                    {getStatusText(oem.status)}
                  </Typography>
                </TableCell>
                {decodedPermissions.includes('manageOEM') && (
                  <TableCell>
                    <Button component={Link} to={`Updateoem/${oem.id}`}>
                      <EditIcon />
                    </Button>

                    <Button onClick={() => handleDelete(oem.id)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
