import { Navigate, createBrowserRouter } from 'react-router-dom'
import App from './App'
import Addoem from './component/oem/addoempage'
import { Oem } from './component/oem/oem'
import { OemBase } from './component/oem/oem_base'

import AddDevice from './component/device/adddevice'
import { Device } from './component/device/device'
import { DeviceBase } from './component/device/device_base'
import UpdateDevice from './component/device/updatedevice'
import AddDongle from './component/dongle/adddongle'
import { Dongle } from './component/dongle/dongle'
import { DongleBase } from './component/dongle/dongle_base'
import UpdateDongle from './component/dongle/updatedongle'
import Login from './component/frontpage/loginpage'
import Updateoem from './component/oem/updateoem'
import Addregions from './component/regions/addregions'
import { RegionsBase } from './component/regions/region_base'
import Regions from './component/regions/regions'
import Updateregions from './component/regions/updateregions'
import AddSuboem from './component/suboem/addsuboempage'
import { SubOem } from './component/suboem/suboem'
import { SubOemBase } from './component/suboem/suboem_base'
import UpdateSuboem from './component/suboem/updatesuboem'
// import AddUsers from './component/users/adduser'
// import UpdateUser from './component/users/updateuser'
import { Users } from './component/users/users'
import { UserBase } from './component/users/usersbase'
import AddEcu from './component/ecu/addecu'
import { Ecu } from './component/ecu/ecu'
import { EcuBase } from './component/ecu/ecu_base'
import UpdateEcu from './component/ecu/updateecu'
import { Submodel } from './component/submodel/submodel'
import UpdateSubmodel from './component/submodel/updatesubmodel'
import AddVehiclemodel from './component/vehiclemodel/addvehiclemodel'
import { Vehiclemodel } from './component/vehiclemodel/vehiclemodel'
import { VehiclemodelsBase } from './component/vehiclemodel/vehiclemodel_base'
import AddWorkshop from './component/workshop/addworkshop'
import UpdateWorkshop from './component/workshop/updateworkshop'
import { Workshop } from './component/workshop/workshop'
import { WorkshopBase } from './component/workshop/workshop_base'
import Addworkshopgroup from './component/workshopgroup/addworkshopgroup'
import Updateworkshopgroup from './component/workshopgroup/updateworkshopgroup'
import { Workshopgroup } from './component/workshopgroup/workshopgroup'
import { VehicleSegment } from './component/vehiclesegment/vehiclesegment'
import { VechilesegmentBase } from './component/vehiclesegment/vehiclesegment_base'
import UpdateVehiclemodel from './component/vehiclemodel/updatevehiclemodel'
import { WorkshopgroupBase } from './component/workshopgroup/workshopgroup_base'
import AddVehiclesegment from './component/vehiclesegment/addvehiclesegment'
import UpdateVechileSegment from './component/vehiclesegment/updatevehiclesegment'

import PrivateRoutes from './component/auth'

const router = createBrowserRouter([
  {
    path: '',
    children: [
      {
        index: true,
        element: <Navigate to='login' />,
      },
      {
        path: 'login',
        element: <Login />,
      },
    ],
  },
  {
    path: 'auth',
    element: <App />,
    children: [
      {
        index: true,
        element: <Navigate to='dashboard' />,
      },

      {
        path: 'dashboard',
        element: <PrivateRoutes />,
      },
      {
        path: 'oem',
        element: <OemBase />,
        children: [
          {
            index: true,
            element: <Oem />,
          },
          {
            path: 'addoem',
            element: <Addoem />,
          },
          {
            path: 'updateoem/:id',
            element: <Updateoem />,
          },
        ],
      },
      {
        path: 'suboem',
        element: <SubOemBase />,
        children: [
          {
            index: true,
            element: <SubOem />,
          },
          {
            path: 'addsuboem',
            element: <AddSuboem />,
          },

          {
            path: 'updatesuboem/:id',
            element: <UpdateSuboem />,
          },
        ],
      },
      {
        path: 'regions',
        element: <RegionsBase />,
        children: [
          {
            index: true,
            element: <Regions />,
          },
          {
            path: 'addregions',
            element: <Addregions />,
          },
          {
            path: 'updateregions/:id',
            element: <Updateregions />,
          },
        ],
      },
      {
        path: 'workshopgroup',
        element: <WorkshopgroupBase />,
        children: [
          {
            index: true,
            element: <Workshopgroup />,
          },
          {
            path: 'addworkshopgroup',
            element: <Addworkshopgroup />,
          },
          {
            path: 'updateworkshopgroup/:id',
            element: <Updateworkshopgroup />,
          },
        ],
      },
      {
        path: 'workshop',
        element: <WorkshopBase />,
        children: [
          {
            index: true,
            element: <Workshop />,
          },
          {
            path: 'addworkshop',
            element: <AddWorkshop />,
          },
          {
            path: 'updateworkshop/:id',
            element: <UpdateWorkshop />,
          },
        ],
      },
      {
        path: 'users',
        element: <UserBase />,
        children: [
          {
            index: true,
            element: <Users />,
          },
          // {
          //   path: 'addusers',
          //   element: <AddUsers />,
          // },
          // {
          //   path: 'updateusers',
          //   element: <UpdateUser />,
          // },
        ],
      },
      {
        path: 'device',
        element: <DeviceBase />,
        children: [
          {
            index: true,
            element: <Device />,
          },
          {
            path: 'adddevice',
            element: <AddDevice />,
          },
          {
            path: 'updatedevice/:id',
            element: <UpdateDevice />,
          },
        ],
      },
      {
        path: 'dongal',
        element: <DongleBase />,
        children: [
          {
            index: true,
            element: <Dongle />,
          },
          {
            path: 'adddongal',
            element: <AddDongle />,
          },
          {
            path: 'updatedongal',
            element: <UpdateDongle />,
          },
        ],
      },
      {
        path: 'vehiclesegment',
        element: <VechilesegmentBase />,
        children: [
          {
            index: true,
            element: <VehicleSegment />,
          },
          {
            path: 'addvehiclesegment',
            element: <AddVehiclesegment />,
          },
          {
            path: 'updatevehiclesegment',
            element: <UpdateVehiclemodel />,
          },
        ],
      },
      {
        path: 'vehiclemodel',
        element: <VehiclemodelsBase />,
        children: [
          {
            index: true,
            element: <Vehiclemodel />,
          },
          {
            path: 'addvehiclemodel',
            element: <AddVehiclemodel />,
          },
          {
            path: 'updatevehiclemodel',
            element: <UpdateVechileSegment />,
          },
        ],
      },
      {
        path: 'ecu',
        element: <EcuBase />,
        children: [
          {
            index: true,
            element: <Ecu />,
          },
          {
            path: 'addecu',
            element: <AddEcu />,
          },
          {
            path: 'updateecu',
            element: <UpdateEcu />,
          },
        ],
      },
      {
        path: 'submodel',
        element: <SubOemBase />,
        children: [
          {
            index: true,
            element: <Submodel />,
          },
          {
            path: 'addsubmodel',
            element: <AddSuboem />,
          },
          {
            path: 'updatesubmodel',
            element: <UpdateSubmodel />,
          },
        ],
      },
    ],
  },
])

export default router
// git init
