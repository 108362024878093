import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'
import { oemid, subOemid } from '../token'

interface Submodel {
  name: string
  description: string
  features: string
  trims_details: string
  color: string
  attachment: string
  vehicleModel: string
  is_active: boolean
}

const UpdateSubmodel: React.FC = () => {
  // const location = useLocation()
  // const submodel: Submodel = location.state
  // const { id } = useParams<{ id: string }>()
  const [name, setname] = useState('')
  const [description, setdescription] = useState('')
  const [features, setfeatures] = useState('')
  const [trims_details, settrims_details] = useState('')
  const [color, setcolor] = useState('')
  const [attachment, setattachment] = useState('')
  const [vehicleModel, setvehiclemodel] = useState('')
  const [is_active, setis_active] = useState(Boolean)

  const [nameError, setnameError] = useState(false)
  const [descriptionError, setdescriptionError] = useState(false)
  const [featuresError, setfeaturesError] = useState(false)
  const [trims_detailsError, settrims_detailsError] = useState(false)
  const [colorError, setcolorError] = useState(false)
  const [attachmentError, setattachmentError] = useState(false)
  const [vehicleModelError, setvehiclemodelError] = useState(false)
  const [_is_activeError, setis_activeError] = useState(Boolean)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setnameError(false)
    setdescriptionError(false)
    setfeaturesError(false)
    settrims_detailsError(false)
    setcolorError(false)
    setattachmentError(false)
    setvehiclemodelError(false)
    setis_activeError(false)

    if (name === '') {
      setnameError(true)
    }
    if (description === '') {
      setdescriptionError(true)
    }
    if (features === '') {
      setfeaturesError(true)
    }
    if (trims_details === '') {
      settrims_detailsError(true)
    }
    if (color === '') {
      setcolorError(true)
    }
    if (attachment === '') {
      setattachmentError(true)
    }
    if (vehicleModel === '') {
      setattachmentError(true)
    }

    if (
      name &&
      description &&
      features &&
      trims_details &&
      color &&
      attachment &&
      vehicleModel &&
      is_active
    ) {
      try {
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/vehiclesegment/1/vehiclemodel/1/submode`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify({
              name,
              description,
              features,
              trims_details,
              color,
              attachment,
              vehicleModel,
              is_active,
            }),
          },
        )

        if (response.ok) {
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/vehiclesegment/1/vehiclemodel/1/submode`,
          )
            .then((response) => response.json())
            .then((SubmodelDetails: Submodel) => {
              setname(SubmodelDetails.name)
              setdescription(SubmodelDetails.description)
              settrims_details(SubmodelDetails.trims_details)
              setcolor(SubmodelDetails.color)
              setfeatures(SubmodelDetails.features)
              setis_active(SubmodelDetails.is_active)
              setattachment(SubmodelDetails.attachment)
              setvehiclemodel(SubmodelDetails.vehicleModel)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })

          setSnackbarOpen(true)
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Vehicle Sub-Model
    </Typography>,
    <Typography key='2' color='text.primary'>
      Add Vehicle Sub-Model
    </Typography>,
  ]

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl>
            <Button variant='outlined' component={RouterLink} to='../'>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='Name'
            label='Name'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            error={nameError}
            helperText={nameError ? ' Name is  required' : ''}
          ></TextField>
        </FormControl>

        <FormControl required variant='outlined' margin='normal'>
          <TextField
            id='description'
            label='Description'
            variant='outlined'
            onChange={(e) => setdescription(e.target.value)}
            value={description}
            error={descriptionError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            id='feature'
            label='feature'
            variant='outlined'
            onChange={(e) => setfeatures(e.target.value)}
            value={features}
            error={featuresError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            id='trims'
            label='trims-details'
            variant='outlined'
            onChange={(e) => settrims_details(e.target.value)}
            value={trims_details}
            error={trims_detailsError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            id='color'
            label='color'
            variant='outlined'
            onChange={(e) => setcolor(e.target.value)}
            value={color}
            error={colorError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            id='attachments'
            label='attachments'
            variant='outlined'
            onChange={(e) => setattachment(e.target.value)}
            value={attachment}
            error={attachmentError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            id='vehiclemode;'
            label='vehiclemodel'
            variant='outlined'
            onChange={(e) => setvehiclemodel(e.target.value)}
            value={vehicleModel}
            error={vehicleModelError}
          />
        </FormControl>

        <FormControl required variant='outlined' margin='normal'>
          <FormControlLabel
            value='start'
            control={
              <Checkbox checked={is_active} onChange={(e) => setis_active(e.target.checked)} />
            }
            label='Is Active'
            labelPlacement='start'
          />
          <Button type='submit' variant='outlined'>
            Add Region
          </Button>
        </FormControl>

        <FormControl>
          <Button className='clearButton' variant='contained' color='secondary'>
            Clear
          </Button>
        </FormControl>
        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'OEM Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : error === 'At least one Diad is required'
                    ? 'At least one Diad is required'
                    : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}

export default UpdateSubmodel
