import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  Breadcrumbs,
  TextField,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'
import { oemid } from '../token'

interface WorkshopGroup {
  name: string
  description: string
  status: boolean
}

interface TestAPIResponse {
  success: string
}

const AddWorkshopGroup: React.FC = () => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [region, setRegion] = useState('')
  const [subOem, setSubOem] = useState('')
  const [nameError, setNameError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [regionError, setRegionError] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const navigate = useNavigate()

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setNameError(false)
    setDescriptionError(false)
    setRegionError(false)

    if (name === '') {
      setNameError(true)
      setError('Name is required')
    }
    if (description === '') {
      setDescriptionError(true)
      setError('Description is Required')
    }
    if (region === '') {
      setRegionError(true)
      setError('Region is required')
    }

    if (name && description && region) {
      try {
        const workshopGroup: WorkshopGroup = {
          name: name,
          description: description,
          status: isActive,
        }

        const token: string | null = localStorage.getItem('token')
        if (token === null) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOem}/region/${region}/workshopgroup/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify(workshopGroup),
          },
        )

        if (response.ok) {
          const responseData: TestAPIResponse = await response.json()
          console.log('API Response:', responseData)

          setName('')
          setDescription('')
          setRegion('')
          setSubOem('')
        } else {
          console.error('Error while making POST request:', response.status, response.statusText)
          setError('Error submitting the form. Please try again.')
        }
      } catch (error) {
        console.error('Error while making POST request:', error)
        setError('Error submitting the form. Please try again.')
      }
    }
  }

  const handleClear = () => {
    setName('')
    setDescription('')
    setRegion('')
    setSubOem('')
    setNameError(false)
    setDescriptionError(false)
    setRegionError(false)
  }

  const handleBack = () => {
    navigate(-1)
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Dealer Principle
    </Typography>,
    <Typography
      key='2'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      ADD Dealer Principle
    </Typography>,
  ]

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
          {breadcrumbs}
        </Breadcrumbs>

        <Grid>
          <FormControl sx={{ alignSelf: 'flex-end' }}>
            <Button variant='outlined' onClick={handleBack}>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label='Name'
            variant='outlined'
            onChange={(e) => setName(e.target.value)}
            value={name}
            error={nameError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='description'
            label='Description'
            variant='outlined'
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            error={descriptionError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='region'
            label='Region'
            variant='outlined'
            onChange={(e) => setRegion(e.target.value)}
            value={region}
            error={regionError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='subOem'
            label='SubOem'
            variant='outlined'
            onChange={(e) => setSubOem(e.target.value)}
            value={subOem}
          />
        </FormControl>

        <FormControlLabel
          control={<Checkbox checked={isActive} onChange={() => setIsActive(!isActive)} />}
          label='Is Active'
          labelPlacement='start'
        />
        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Add Dealer Principle
          </Button>
        </FormControl>
        <FormControl>
          <Button
            className='clearButton'
            variant='contained'
            color='secondary'
            onClick={handleClear}
          >
            Clear
          </Button>
        </FormControl>
        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'OEM Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : error === 'At least one Diad is required'
                    ? 'At least one Diad is required'
                    : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}

export default AddWorkshopGroup
