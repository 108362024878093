import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material'

import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { oemid, subOemid } from '../token'

export interface vehiclemodel {
  id: number
  name: string
  is_active: boolean
  year: number
  vehicleSegment: string
  subOem: number
}

const UpdateVehiclemodel: React.FC = () => {
  const [name, setname] = useState('')
  const [year, setyear] = useState('')
  const [subOem, _setsubOem] = useState('')
  const [vehicleSegment, setvehicleSegment] = useState('')
  const [is_active, setis_active] = useState(Boolean)

  const [nameError, setnameError] = useState(false)
  const [yearError, setyearError] = useState(false)
  const [subOemError, setsubOemError] = useState(false)
  const [vehicleSegmentError, setvehicleSegmentError] = useState(false)
  const [_is_activeError, setis_activeError] = useState(Boolean)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)
    setyearError(false)
    setsubOemError(false)
    setvehicleSegmentError(false)
    setis_activeError(false)

    if (name === '') {
      setnameError(true)
    }
    if (year === '') {
      setyearError(true)
    }
    if (subOem === '') {
      setsubOemError(true)
    }
    if (vehicleSegment === '') {
      setvehicleSegmentError(true)
    }

    if (name && year && subOem && vehicleSegment && is_active) {
      try {
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/vehiclesegment/1/vehiclemodel/1/submodel`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify({
              name,
              vehicleSegment,
              year,
              subOem,
              is_active,
            }),
          },
        )

        if (response.ok) {
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/vehiclesegment/1/vehiclemodel/1/submode`,
          )
            .then((response) => response.json())
            .then((vehiclemodelDetails: vehiclemodel) => {
              setname(vehiclemodelDetails.name)
              // setsubOem(vehiclemodelDetails.subOem)
              // setyear(vehiclemodelDetails.year)
              setvehicleSegment(vehiclemodelDetails.vehicleSegment)
              setis_active(vehiclemodelDetails.is_active)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  return (
    <Box
      display='grid'
      justifyContent='center'
      alignItems='center'
      component='form'
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <FormControl>
        <Button variant='outlined' component={RouterLink} to='../'>
          Back
        </Button>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='username'
          label='User Name'
          variant='outlined'
          onChange={(e) => setname(e.target.value)}
          value={name}
          error={nameError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='year'
          label='year '
          variant='outlined'
          onChange={(e) => setyear(e.target.value)}
          value={year}
          error={yearError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='vehicle Segment'
          label='vechile Segment '
          variant='outlined'
          onChange={(e) => setvehicleSegment(e.target.value)}
          value={vehicleSegment}
          error={vehicleSegmentError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='suboem'
          label='suboem '
          variant='outlined'
          onChange={(e) => setvehicleSegment(e.target.value)}
          value={subOem}
          error={subOemError}
        ></TextField>
      </FormControl>

      <FormControlLabel
        value='start'
        control={<Checkbox />}
        label='Is Active'
        labelPlacement='start'
      />

      <FormControl required variant='outlined' margin='normal'>
        <Button type='submit' variant='outlined'>
          Update Vehicle model
        </Button>
      </FormControl>

      <FormControl>
        <Button className='clearButton' variant='contained' color='secondary'>
          Clear
        </Button>
      </FormControl>
    </Box>
  )
}

export default UpdateVehiclemodel
