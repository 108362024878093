import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'

interface Device {
  id: number
  device_mac_address: string
  device_type: string
  created_by: string

  details: string
  type: string
}

export const Device: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [devices, setDevice] = useState<Device[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'Device' })

    const fetchDevice = async () => {
      try {
        const token: string | null = localStorage.getItem('token')
        //  console.log('token', token)
        if (!token) {
          return
        }

        const deviceResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/device/4`, {
          headers: {
            'x-api-key': token,
          },
        })

        const decodedToken: string | null = localStorage.getItem('decodedToken')
        if (decodedToken === null) {
          return
        }

        const jsonData = await deviceResponse.json()
        const deviceArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setDevice(deviceArray)
        // console.log('deviceArray : ', deviceArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchDevice()
  }, [updateContext])
  const handleDelete = async (id: number) => {
    try {
      const token: string | null = localStorage.getItem('token')
      if (!token) {
        return
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/device/${id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': token,
        },
      })

      if (response.ok) {
        setDevice((prevDevice) => prevDevice.filter((device) => device.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Devices
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      {/* {userPermissions.includes('manageOEM') && ( */}
      <Avatar
        component={Link}
        to='adddevice'
        sx={{
          bgcolor: green[500],
          float: 'right',
          marginRight: 5,
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <AddIcon />
      </Avatar>
      {/* )} */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Device Address</TableCell>
              <TableCell>Device Type</TableCell>
              <TableCell>Created by </TableCell>
              <TableCell>workshop</TableCell>
              <TableCell>Actions</TableCell>
              {/* {userPermissions.includes('manageOem') && */}
            </TableRow>
          </TableHead>
          <TableBody>
            {devices.map((device) => (
              <TableRow key={device.id}>
                <TableCell>{device.id}</TableCell>
                <TableCell>{device.device_mac_address}</TableCell>
                <TableCell>{device.device_type}</TableCell>
                <TableCell>{device.created_by}</TableCell>
                <TableCell>{device.details}</TableCell>

                {/* <TableCell>{device.user}</TableCell> */}
                {/* <TableCell>
                  <FormControlLabel
                    value='start'
                    control={<Checkbox />}
                    label=''
                    labelPlacement='start'
                  />
                </TableCell> */}

                <TableCell>
                  {/* {userPermissions.includes('manageDevice') && ( */}
                  <Button component={Link} to={`Updatedevice/${device.id}`} state={device}>
                    <EditIcon />
                  </Button>
                  {/* )} */}
                  {/* {userPermissions.includes('manageOEM') && ( */}
                  <Button onClick={() => handleDelete(device.id)}>
                    <DeleteIcon />
                  </Button>
                  {/* )} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
