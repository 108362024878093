import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Breadcrumbs,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'
import { oemid, subOemid } from '../token'

interface VehicleSegment {
  name: string
  logo_url: string
  is_active: boolean
  subOem: number
  createdon: number
}

const UpdateVechileSegment: React.FC = () => {
  const { id } = useParams()
  const [name, setName] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [subOem, setSubOem] = useState('')
  const [is_active, setIsActive] = useState(false)
  const [createdon, setCreatedOn] = useState('')
  // const [created_onErorr, setCreatedOnError] = useState(false)
  const [_subOemErorr, setSubOemError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [logoUrlError, setLogoUrlError] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const navigate = useNavigate()

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/submodel/${subOemid}/vehiclesegment/${id}`,
    )
      .then((response) => response.json())
      .then((vehiclesegmentDetails: VehicleSegment) => {
        setName(vehiclesegmentDetails.name)
        setLogoUrl(vehiclesegmentDetails.logo_url)
        setSubOem(vehiclesegmentDetails.subOem.toString())
        setCreatedOn(vehiclesegmentDetails.createdon.toString())
        setIsActive(vehiclesegmentDetails.is_active)
      })
      .catch((error) => {
        console.error('Error while fetching details:', error)
      })
  }, [id])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setNameError(false)
    setLogoUrlError(false)
    setSubOemError(false)

    if (name === '') {
      setNameError(true)
      return
    }
    if (logoUrl === '') {
      setLogoUrlError(true)
      return
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/submodel/${subOemid}/vehiclesegment/${id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, logo_url: logoUrl, subOem: parseInt(subOem), createdon }),
        },
      )

      if (response.ok) {
        setSnackbarOpen(true)
      } else {
        console.error('Error while making PUT request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making PUT request:', error)
    }
  }

  const handleClear = () => {
    setName('')
    setLogoUrl('')
    setSubOem('')
    setCreatedOn('')
    setIsActive(false)
  }

  const handleBack = () => {
    navigate('../')
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      OEM
    </Typography>,
    <Typography key='3' color='text.primary'>
      UPDATE OEM
    </Typography>,
  ]

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl sx={{ alignSelf: 'flex-end' }}>
            <Button variant='outlined' onClick={handleBack}>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label='Name'
            variant='outlined'
            onChange={(e) => setName(e.target.value)}
            value={name}
            error={nameError}
            helperText={nameError ? 'Name is required' : ''}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='logoUrl'
            label='Logo URL'
            variant='outlined'
            onChange={(e) => setLogoUrl(e.target.value)}
            value={logoUrl}
            error={logoUrlError}
            helperText={logoUrlError ? 'Logo URL is required' : ''}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='subOem'
            label='Sub OEM'
            variant='outlined'
            onChange={(e) => setSubOem(e.target.value)}
            value={subOem}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='createdOn'
            label='Created On'
            variant='outlined'
            onChange={(e) => setCreatedOn(e.target.value)}
            value={createdon}
          />
        </FormControl>

        <FormControlLabel
          control={<Checkbox checked={is_active} onChange={(e) => setIsActive(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />

        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Update Vehicle Segment
          </Button>
        </FormControl>

        <Button className='clearButton' variant='contained' color='secondary' onClick={handleClear}>
          Clear
        </Button>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message='Updated successfully!'
        />
      </Box>
    </Box>
  )
}

export default UpdateVechileSegment
