import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import { oemid } from '../token'

interface SubOem {
  name: string
  is_active: boolean
}

const UpdateSuboem: React.FC = () => {
  const location = useLocation()
  const suboem: SubOem = location.state
  const { id } = useParams<{ id: string }>()
  const [name, setName] = useState(suboem.name)
  const [is_active, setIsActive] = useState(suboem.is_active)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [nameError, setNameError] = useState(false)

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setNameError(false)

    if (name === '') {
      setNameError(true)
    }

    if (name) {
      try {
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify({ name, is_active }),
          },
        )

        if (response.ok) {
          fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${id}`)
            .then((response) => response.json())
            .then((SubOemDetails: SubOem) => {
              setName(SubOemDetails.name)
              setIsActive(SubOemDetails.is_active)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })

          setSnackbarOpen(true)
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  const handleClear = () => {
    setNameError(false)
  }

  const breadcrumbs = [
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      SubOEM
    </Typography>,
    <Typography
      key='3'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Add SubOEM
    </Typography>,
  ]

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl>
            <Button variant='outlined' component={RouterLink} to='../'>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='suboem'
            label='Sub-oem'
            variant='outlined'
            onChange={(e) => setName(e.target.value)}
            value={name}
            error={nameError}
          />
        </FormControl>

        <FormControlLabel
          value='start'
          control={<Checkbox checked={is_active} onChange={(e) => setIsActive(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />

        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Update Suboem
          </Button>
        </FormControl>

        <FormControl>
          <Button
            className='clearButton'
            variant='contained'
            color='secondary'
            onClick={handleClear}
          >
            Clear
          </Button>
        </FormControl>

        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'OEM Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : error === 'At least one Diad is required'
                    ? 'At least one Diad is required'
                    : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}

export default UpdateSuboem
