interface DecodedToken {
  oem: number
  subOem?: number
  region?: number
  workshopGroup?: number
  workshop?: number

  role: {
    permissions: string[]
  }
}

export const token: string | null = localStorage.getItem('token')
if (!token) {
  console.log('Decoded token not found')
}
console.log('token:', token)

const decodedTokenString: string | null = localStorage.getItem('decodedToken')
let decodedTokenObject: DecodedToken = {
  oem: 0,
  subOem: 0,
  region: 0,
  workshopGroup: 0,
  workshop: 0,
  role: {
    permissions: [],
  },
}

if (decodedTokenString) {
  console.log('decodedToken:', decodedTokenString)
  decodedTokenObject = JSON.parse(decodedTokenString)
}

export const decodedPermissions: string[] = decodedTokenObject.role.permissions

export const oemid: number = decodedTokenObject.oem || 0
export const subOemid: number = decodedTokenObject.subOem || 0
export const regionid: number = decodedTokenObject.region || 0
export const workshopgroupid: number = decodedTokenObject.workshopGroup || 0
export const workshopid: number = decodedTokenObject.workshop || 0

console.log('Permissions:', decodedPermissions)
