import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

interface Dongle {
  id: number
  device_mac_address: string
  created_by: string
}

interface TestAPIResponse {
  success: string
}

const UpdateDongle: React.FC = () => {
  const location = useLocation()
  const dongle = location.state

  const [device_mac_address, setdevice_mac_adderss] = useState(dongle?.device_mac_address || '')
  const [created_by, setcreated_by] = useState('')

  const [device_mac_addressError, setdevice_mac_addressError] = useState(false)
  const [created_byError, setcreated_byError] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setdevice_mac_addressError(false)
    setcreated_byError(false)

    if (device_mac_address === '') {
      setdevice_mac_addressError(true)
    }
    if (created_by === '') {
      setcreated_byError(true)
    }

    if (device_mac_address && created_by) {
      try {
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/devices/1/dongle/1`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': token,
          },
          body: JSON.stringify({ device_mac_address }),
        })

        if (response.ok) {
          const responsData: TestAPIResponse = await response.json()
          console.log('RESP:', responsData)

          fetch(`${process.env.REACT_APP_API_BASE_URL}devices/1/dongle/1`)
            .then((response) => response.json())
            .then((DongleDetails: Dongle) => {
              setdevice_mac_adderss(DongleDetails.device_mac_address)

              setcreated_by(DongleDetails.created_by)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })
          // setSnackbarOpen(true)
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  return (
    <Box
      display='grid'
      justifyContent='center'
      alignItems='center'
      component='form'
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <FormControl>
        <Button variant='outlined' component={RouterLink} to='../'>
          Back
        </Button>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='username'
          label='Device Mac ADDRESS'
          variant='outlined'
          onChange={(e) => setdevice_mac_adderss(e.target.value)}
          value={device_mac_address}
          error={device_mac_addressError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='firstname'
          label='created on'
          variant='outlined'
          onChange={(e) => setcreated_by(e.target.value)}
          value={created_by}
          error={created_byError}
        ></TextField>
      </FormControl>

      <FormControlLabel
        value='start'
        control={<Checkbox />}
        label='Is Active'
        labelPlacement='start'
      />

      <FormControl required variant='outlined' margin='normal'>
        <Button type='submit' variant='outlined'>
          Update Dongal
        </Button>
      </FormControl>

      <FormControl>
        <Button className='clearButton' variant='contained' color='secondary'>
          Clear
        </Button>
      </FormControl>
    </Box>
  )
}

export default UpdateDongle
