import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface Device {
  device_mac_address: string
  device_type: string
  created_by: string
  user: number
  details: string
  status: boolean
}

const _breadcrumbs = [
  <Typography key='1' color='inherit'>
    Devices
  </Typography>,
  <Typography key='2' color='text.primary'>
    Add Devices
  </Typography>,
]

const AddDevice: React.FC = () => {
  const [device_mac_address, setdevice_mac_adderss] = useState('')
  const [device_type, setdevice_type] = useState('')
  const [created_by, setcreated_by] = useState('')
  const [details, setdetails] = useState('')
  const [user, setuser] = useState('')
  const [status, setstatus] = useState(Boolean)

  const [device_mac_addressError, setdevice_mac_adderssError] = useState(false)
  const [device_typeError, setdevice_typeError] = useState(false)
  const [created_byError, setcreated_byError] = useState(false)
  const [detailsError, setdetailsError] = useState(false)
  const [userError, setuserError] = useState(false)
  const [_statusError, setstatusError] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setdevice_mac_adderssError(false)
    setdevice_typeError(false)
    setcreated_byError(false)
    setdetailsError(false)
    setstatusError(false)
    setuserError(false)

    if (device_mac_address === '') {
      setdevice_mac_adderssError(true)
    }
    if (device_type === '') {
      setdevice_typeError(true)
    }
    if (created_by === '') {
      setcreated_byError(true)
    }
    if (details === '') {
      setdetailsError(true)
    }
    if (user === '') {
      setuserError(true)
    }

    if (device_mac_address && device_type && created_by && details && user && status) {
      try {
        const DeviceVar: Device = {
          device_mac_address: device_mac_address,
          device_type: device_type,
          created_by: created_by,
          user: 37,
          details: details,
          status: true,
        }
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/device`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': token,
          },
          body: JSON.stringify(DeviceVar),
        })

        console.log('API response:', response)

        if (response.ok) {
          setdevice_mac_adderss('')
          setdevice_type('')
          setdetails('')
          setuser('')

          // setsuboem('')
          console.log('Form submitted successfully')
        } else {
          console.error('Error while making POST request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making POST request:', error)
      }
    }
  }
  // co
  return (
    <Box
      display='grid'
      justifyContent='center'
      alignItems='center'
      component='form'
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <FormControl>
        <Button variant='outlined' component={RouterLink} to='../'>
          Back
        </Button>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='Device'
          label='Device Mac Address'
          variant='outlined'
          onChange={(e) => setdevice_mac_adderss(e.target.value)}
          value={device_mac_address}
          error={device_mac_addressError}
        />
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='devicetype'
          label='Device Type'
          variant='outlined'
          onChange={(e) => setdevice_type(e.target.value)}
          value={device_type}
          error={device_typeError}
        />
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='created'
          label='Created By'
          variant='outlined'
          onChange={(e) => setcreated_by(e.target.value)}
          value={created_by}
          error={created_byError}
        />
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='details'
          label='Details'
          variant='outlined'
          onChange={(e) => setdetails(e.target.value)}
          value={details}
          error={detailsError}
        />
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='user'
          label='user'
          variant='outlined'
          onChange={(e) => setuser(e.target.value)}
          value={user}
          error={userError}
        />
      </FormControl>
      <FormControlLabel
        value='start'
        control={<Checkbox checked={status} onChange={(e) => setstatus(e.target.checked)} />}
        label='Is Active'
        labelPlacement='start'
      />
      <FormControl required variant='outlined' margin='normal'>
        <Button type='submit' variant='outlined'>
          Add Device
        </Button>
      </FormControl>
      <FormControl>
        <Button className='clearButton' variant='contained' color='secondary'>
          Clear
        </Button>
      </FormControl>
    </Box>
  )
}

export default AddDevice
