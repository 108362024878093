import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'

export interface Dongle {
  id: number
  device_mac_address: string
  created_by: string
}

export const Dongle: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [dongle, setDongle] = useState<Dongle[]>([])
  useEffect(() => {
    updateContext({ pageTitle: 'Dongle' })
    const fetchDongle = async () => {
      try {
        const token: string | null = localStorage.getItem('token')
        console.log('token', token)
        if (!token) {
          return
        }

        const deviceId = 1

        const dongalResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/device/${deviceId}/dongle`,
          {
            headers: {
              'x-api-key': token,
            },
          },
        )

        const jsonData = await dongalResponse.json()
        const dongleArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setDongle(dongleArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchDongle()
  }, [updateContext])
  const handleDelete = async (id: number) => {
    try {
      const token: string | null = localStorage.getItem('token')
      if (!token) {
        return
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/device/${id}`, {
        method: 'DELETE',
        headers: {
          'x-api-key': token,
        },
      })

      if (response.ok) {
        setDongle((prevDongle) => prevDongle.filter((dongle) => dongle.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Dongle
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='adddongle'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>

              <TableCell>Created by </TableCell>
              <TableCell>Created-On</TableCell>
              <TableCell>Is Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dongle.map((dongle) => (
              <TableRow key={dongle.id}>
                <TableCell>{dongle.id}</TableCell>

                <TableCell>{dongle.device_mac_address}</TableCell>
                <TableCell>{dongle.created_by}</TableCell>
                <TableCell>
                  <FormControlLabel
                    value='start'
                    control={<Checkbox />}
                    label=''
                    labelPlacement='start'
                  />
                </TableCell>

                <TableCell>
                  <Button component={Link} to='updatedongle'>
                    <EditIcon />
                  </Button>
                  <Button onClick={() => handleDelete(dongle.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
