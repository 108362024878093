import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'

interface Oem {
  oem_name: string
  logo_url: string
  status: boolean
  diads: number[]
}

const Addoem: React.FC = () => {
  const [oem_name, setoem_name] = useState('')
  const [logo_url, setLogoUrl] = useState('')
  const [inputDiads, setInputDiads] = useState<string>('')
  const [diads, setDiads] = useState<number[]>([])
  const [oem_nameError, setoem_nameError] = useState(false)
  const [logoUrlError, setLogoUrlError] = useState(false)
  const [diadsError, setDiadsError] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [isActive, setIsActive] = useState(Boolean)

  const navigate = useNavigate()

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setoem_nameError(false)
    setLogoUrlError(false)
    setDiadsError(false)

    if (oem_name === '') {
      setoem_nameError(true)
      setError('Name is required')
    }
    if (logo_url === '') {
      setLogoUrlError(true)
      setError('Logo URL is required')
    }
    if (diads.length === 0) {
      setDiadsError(true)
      setError('At least one Diad is required')
    }
    if (oem_name && logo_url && diads.length > 0) {
      try {
        const OemVar: Oem = {
          oem_name: oem_name,
          logo_url: logo_url,
          status: isActive,
          diads: diads,
        }
        const token: string | null = localStorage.getItem('token')
        if (token === null) {
          return
        }

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems`, {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': token,
          },
          body: JSON.stringify(OemVar),
        })

        if (response.ok) {
          setSnackbarOpen(true)

          setoem_name('')
          setLogoUrl('')
          setDiads([])
        } else {
          console.error('Error while making POST request:', response.status, response.statusText)
          setError('Error submitting the form. Please try again.')
        }
      } catch (error) {
        console.error('Error while making POST request:', error)
        setError('Error submitting the form. Please try again.')
      }
    }
  }

  const handleClear = () => {
    setoem_name('')
    setLogoUrl('')
    setDiads([])
    setoem_nameError(false)
    setLogoUrlError(false)
    setDiadsError(false)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputDiads(event.target.value)
  }

  const handleAddChip = () => {
    if (inputDiads.trim() !== '') {
      setDiadsError(false)
      setDiads((prevDiads) => [...prevDiads, parseInt(inputDiads, 10)])
      setInputDiads('')
    } else {
      setDiadsError(true)
    }
  }

  const handleDeleteChip = (chipToDelete: number) => () => {
    setDiads((prevDiads) => prevDiads.filter((chip) => chip !== chipToDelete))
  }

  const handleBack = () => {
    navigate(-1)
  }
  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      OEM
    </Typography>,
    <Typography key='2' color='text.primary'>
      ADD OEM
    </Typography>,
  ]
  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl sx={{ alignSelf: 'flex-end' }}>
            <Button variant='outlined' onClick={handleBack}>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='OEM'
            label='OEM'
            variant='outlined'
            onChange={(e) => setoem_name(e.target.value)}
            value={oem_name}
            error={oem_nameError}
            helperText={oem_nameError ? 'OEM Name is required' : ''}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='logo_url'
            label='Logo URL'
            variant='outlined'
            onChange={(e) => setLogoUrl(e.target.value)}
            value={logo_url}
            error={logoUrlError}
            helperText={logoUrlError ? 'Logo URL is required' : ''}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <Paper
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
              listStyle: 'none',
              p: 0.5,
              m: 0,
            }}
            component='ul'
          >
            {diads.map((data) => (
              <li key={data}>
                <Chip label={data.toString()} onDelete={handleDeleteChip(data)} />
              </li>
            ))}
          </Paper>
          <TextField
            required
            id='diads'
            label='Diads'
            variant='outlined'
            value={inputDiads}
            onChange={handleInputChange}
            onBlur={handleAddChip}
            error={diadsError}
            helperText={diadsError ? 'Diads are required' : ''}
          />
        </FormControl>
        <FormControlLabel
          value='start'
          control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />
        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Add Oem
          </Button>
        </FormControl>
        <FormControl>
          <Button
            className='clearButton'
            variant='contained'
            color='secondary'
            onClick={handleClear}
          >
            Clear
          </Button>
        </FormControl>

        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'OEM Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : error === 'At least one Diad is required'
                    ? 'At least one Diad is required'
                    : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}

export default Addoem
