import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Dashboard from './dashboard'

const PrivateRoutes = () => {
  const token = localStorage.getItem('token')
  const navigate = useNavigate()

  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [token, navigate])

  return token ? <Dashboard /> : null
}

export default PrivateRoutes
