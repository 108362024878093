import React from 'react'
import styled from '@emotion/styled'
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import {
  Box,
  Button,
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import { Logindetails } from './frontpage/loginpage'

const UserBoxButton = styled(Button)(
  () => `
          padding-left: 4px;
          padding-right: 4px;
          color:black;
          background:red;
          &:hover {
            background-color: darkred;
          }
  `,
)

const MenuUserBox = styled(Box)(
  () => `
          background: red; 
          padding: 2;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    `,
)

const UserBoxText = styled(Box)(
  () => `
          text-align: center;
          color:black;
          font-family: "Times New Roman", Times, serif;
       
  `,
)

const UserBoxLabel = styled(Typography)(
  () => `
          font-weight: 600;
          display: block;
          color:white;
             font-size:18px;
  `,
)

const UserBoxDescription = styled(Typography)(
  () => `

          color: white;
          font-size:12px;
  `,
)

function HeaderUserbox({ userDetails }: { userDetails: Logindetails }) {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleSignOut = () => {
    // Clear localStorage here
    localStorage.clear()
  }

  return (
    <>
      <UserBoxButton
        color='secondary'
        ref={ref}
        onClick={handleOpen}
        sx={{ position: 'absolute', right: 18, padding: '5px', borderRadius: '15px' }}
      >
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{userDetails.username}</UserBoxLabel>
            {/* Display username */}
            <UserBoxDescription variant='body2'>{userDetails.username}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 68,
          horizontal: 300,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ textAlign: 'center', justifyItems: 'center' }}
      >
        <MenuUserBox sx={{ minWidth: 240 }} display='flex'>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{userDetails.username}</UserBoxLabel>
            {/* Display username */}
            <UserBoxDescription variant='body2'>{userDetails.username}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component='nav'>
          <ListItem button to='/management/profile/details' component={NavLink}>
            <AccountBoxTwoToneIcon fontSize='small' />
            <ListItemText primary='My Profile' />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button
            color='primary'
            fullWidth
            component={RouterLink}
            to='../Login'
            onClick={handleSignOut}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default HeaderUserbox
