import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { jwtDecode } from 'jwt-decode'

export interface Logindetails {
  username: string
}

export default function Login() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [_userDetails, setUserDetails] = useState<Logindetails | null>(null)

  const navigate = useNavigate()

  const handleLogin = async () => {
    try {
      setError('')
      setLoading(true)

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      })

      if (response.ok) {
        const jsonData = await response.json()
        const decodedToken = jwtDecode(jsonData.token)
        localStorage.setItem('decodedToken', JSON.stringify(decodedToken))
        localStorage.setItem('token', jsonData.token)
        localStorage.setItem('user', JSON.stringify(jsonData)) // Store user details in localStorage
        navigate('../auth/dashboard')
        const userDetailsData: Logindetails = {
          username: username,
        }
        setUserDetails(userDetailsData) // Set userDetails
      } else {
        setError('Invalid username or password')
      }
    } catch (error) {
      console.error('Login error:', error)
      setError('An error occurred during login')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container
      component='main'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '10px',
        borderShadow: 3,
        marginTop: '30px',
        marginBottom: '30px',
        width: '100%',
        padding: '30px',
        height: '95vh',
        backgroundColor: 'white',
      }}
    >
      <Box>
        <AppBar style={{ backgroundColor: 'white', boxShadow: 'none' }}>
          <Avatar
            variant='square'
            alt='Logo'
            src={process.env.PUBLIC_URL + '/image/Navigo-logo.png'}
            sx={{ width: 210, height: 40, padding: 1, marginTop: 1 }}
          />
        </AppBar>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '1px',
          paddingTop: '3px',
          px: 3,
          py: 3,
          gap: '20px',
        }}
      >
        <Box>
          <Typography component='h1' variant='h4'>
            POWERED BY CREATORS AROUND THE WORLD
          </Typography>
          <br />
          <br />
          <Typography
            component='p'
            sx={{
              color: 'grey',
              fontSize: '14px',
            }}
          >
            dont have an account ?
          </Typography>
          <Typography
            component='p'
            sx={{
              color: 'grey',
              fontSize: '14px',
            }}
          >
            Create Account{' '}
          </Typography>
          <Grid
            item
            xs
            sx={{
              color: 'black',
            }}
          ></Grid>
        </Box>
        <Box
          sx={{
            borderRadius: '15px',
            backgroundImage:
              'url(https://source.unsplash.com/white-and-black-truck-near-mountain-at-daytime-lq9PxpwDZUk)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '200px',
            width: '80vh',
            display: 'flex',
            flexDirection: '',
            justifyContent: 'space-between',
            padding: '10px',
            gap: '10px',
          }}
        >
          <Box>
            <Typography sx={{ color: 'black', fontSize: 'large' }}>about Us</Typography>
            <Typography sx={{ color: '' }}>
              The success of every weong your competitors in terms of online sales.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: 4,
          py: 2,
          backgroundImage:
            'url(https://source.unsplash.com/white-and-black-truck-near-mountain-at-daytime-lq9PxpwDZUk)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '90vh',
          borderRadius: '15px',
          width: '900px',
        }}
      >
        <Box
          sx={{
            px: 3,
            py: 4,
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxWidth: '40vh',
            maxHeight: '60vh',

            backgroundColor: 'white',
            borderRadius: '15px',
          }}
        >
          <Typography component='h1' variant='h5'>
            Login to Your Account
          </Typography>
          <Box component='form' noValidate sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              label='Username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type='text'
              variant='standard'
              autoComplete='username'
            />
            <TextField
              margin='normal'
              required
              fullWidth
              label='Password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant='standard'
              autoComplete='current-password'
            />
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label='Remember me'
            />
            <Grid item xs></Grid>
            <Button
              variant='contained'
              fullWidth
              sx={{
                mt: 3,
                mb: 2,
                color: 'white',
                backgroundColor: 'black',
                borderRadius: '5',
              }}
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? 'Logging in...' : 'Login'}
            </Button>
            {error && (
              <Typography color='error' variant='body2'>
                {error}
              </Typography>
            )}
            <Grid
              container
              sx={{
                px: 4,
                py: 3,
                backgroundImage:
                  'url(https://source.unsplash.com/white-and-black-truck-near-mountain-at-daytime-lq9PxpwDZUk)',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '8px',
              }}
            ></Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}
