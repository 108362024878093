import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'
// import usersData from '../userData.json'

export interface User {
  id: number
  username: string
  first_name: string
  last_name: string
  email_id: string
  mobilenumber: number
  password: string
  reports: string
  created_on: number
}

export const Users: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [user, setUser] = useState<User[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'Users' })

    const fetchUser = async () => {
      try {
        const token: string | null = localStorage.getItem('token')

        if (!token) {
          return
        }

        const userResponse = await fetch('https://diagnostic-api.navigolabs.com/user/getAll', {
          headers: {
            'x-api-key': token,
          },
        })

        const jsonData = await userResponse.json()
        const oemArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setUser(oemArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchUser()
  }, [updateContext])

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      users
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='addusers'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.first_name}</TableCell>
                <TableCell>{user.last_name}</TableCell>
                <TableCell>{user.email_id}</TableCell>

                <TableCell>
                  <Button component={Link} to='Updateusers'>
                    <EditIcon />
                  </Button>
                  <Button component={Link} to='addsuboem'>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
