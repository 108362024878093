import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'
import { oemid, subOemid, regionid, workshopgroupid, decodedPermissions } from '../token'
import { Link as RouterLink } from 'react-router-dom'

export interface Workshop {
  id: 0
  name: string
  status: true
  country: string
  city: string
  state: string
  pincode: 0
  address: string
}

export const Workshop: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [Workshop, setWorkshop] = useState<Workshop[]>([])
  const [_userPermissions, _setUserPermissions] = useState<string[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'Dealer' })

    const fetchworkshop = async () => {
      try {
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/region/${regionid}/workshopgroup/${workshopgroupid}/workshop`,
          {
            headers: {
              'x-api-key': token,
            },
          },
        )

        const jsonData = await response.json()
        const WorkshopArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setWorkshop(WorkshopArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchworkshop()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const token: string | null = localStorage.getItem('token')
      if (token === null) {
        return
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/region/${regionid}/workshopgroup/${workshopgroupid}/workshop/${id}`,
        {
          method: 'DELETE',
          headers: {
            'x-api-key': token,
          },
        },
      )

      if (response.ok) {
        setWorkshop((prevWorkshop) => prevWorkshop.filter((workshop) => workshop.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }
  const getStatusText = (status: boolean) => {
    return status ? 'Active' : 'Inactive'
  }

  const getStatusColor = (status: boolean) => {
    return status ? green[500] : red[500]
  }

  const getStatusBackgroundColor = (status: boolean) => {
    return status ? green[100] : red[100]
  }

  const breadcrumbs = [
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Dealer
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      {decodedPermissions.includes('manageDealer') && (
        <Avatar
          component={Link}
          to='addworkshop'
          sx={{
            bgcolor: green[500],
            float: 'right',
            marginRight: 5,
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <AddIcon />
        </Avatar>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>name</TableCell>
              <TableCell>region</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>City</TableCell>
              <TableCell>State</TableCell>

              <TableCell>Address</TableCell>
              {decodedPermissions.includes('manageDealer') && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {Workshop.map((workshop) => (
              <TableRow key={workshop.id}>
                <TableCell>{workshop.id}</TableCell>
                <TableCell>{workshop.name}</TableCell>
                <TableCell>{workshop.country}</TableCell>
                <TableCell>{workshop.city}</TableCell>
                <TableCell>{workshop.state}</TableCell>
                <TableCell>{workshop.pincode}</TableCell>
                <TableCell>{workshop.address}</TableCell>
                <TableCell>
                  <Typography
                    style={{
                      color: getStatusColor(workshop.status),
                      backgroundColor: getStatusBackgroundColor(workshop.status),
                      padding: '5px 10px',
                      borderRadius: '5px',
                    }}
                  >
                    {getStatusText(workshop.status)}
                  </Typography>
                </TableCell>
                {decodedPermissions.includes('manageDealer') && (
                  <TableCell>
                    <Button component={Link} to={`updateworkshop/${workshop.id}`} state={workshop}>
                      <EditIcon />
                    </Button>
                    <Button onClick={() => handleDelete(workshop.id)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
