import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

interface Dongle {
  device_mac_address: string
  created_by: string
}

interface TestAPIResponse {
  success: string
}

const AddDongle: React.FC = () => {
  const [device_mac_address, setdevice_mac_adderss] = useState('')
  const [created_by, setcreated_by] = useState('')

  const [device_mac_addressError, setdevice_mac_addressError] = useState(false)
  const [created_byError, setcreated_byError] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setdevice_mac_addressError(false)
    setcreated_byError(false)

    if (device_mac_address === '') {
      setdevice_mac_addressError(true)
    }
    if (created_by === '') {
      setcreated_byError(true)
    }

    if (device_mac_address && created_by) {
      try {
        const DongleVar: Dongle = {
          device_mac_address: device_mac_address,

          created_by: created_by,
        }
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/device/1/dongle`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(DongleVar),
        })

        console.log('API response:', response)

        if (response.ok) {
          const responseData: TestAPIResponse = await response.json()
          console.log('RESP:', responseData)

          setdevice_mac_adderss('')
          setcreated_by('')

          // setsuboem('')
          console.log('Form submitted successfully')
        } else {
          console.error('Error while making POST request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making POST request:', error)
      }
    }
  }

  return (
    <Box
      display='grid'
      justifyContent='center'
      alignItems='center'
      component='form'
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <FormControl>
        <Button variant='outlined' component={RouterLink} to='../'>
          Back
        </Button>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='Device Mac Address'
          label='Device Mac Address'
          variant='outlined'
          onChange={(e) => setdevice_mac_adderss(e.target.value)}
          value={device_mac_address}
          error={device_mac_addressError}
        ></TextField>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='firstname'
          label='created on'
          variant='outlined'
          onChange={(e) => setcreated_by(e.target.value)}
          value={created_by}
          error={created_byError}
        ></TextField>
      </FormControl>

      <FormControlLabel
        value='start'
        control={<Checkbox />}
        label='Is Active'
        labelPlacement='start'
      />

      <FormControl required variant='outlined' margin='normal'>
        <Button type='submit' variant='outlined'>
          Add Dongal
        </Button>
      </FormControl>

      <FormControl>
        <Button className='clearButton' variant='contained' color='secondary'>
          Clear
        </Button>
      </FormControl>
    </Box>
  )
}

export default AddDongle
