import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'
import { Link as RouterLink } from 'react-router-dom'
import { oemid, decodedPermissions } from '../token'
import { Oem } from '../oem/oem'

interface SubOem {
  id: number
  name: string
  oem: Oem
}

export const SubOem: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [suboems, setSuboems] = useState<SubOem[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'SubOEM' })

    const fetchSuboems = async () => {
      try {
        const token: string | null = localStorage.getItem('token')
        if (!token) {
          return
        }

        const suboemResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem`,
          {
            headers: {
              'x-api-key': token,
            },
          },
        )

        const jsonData = await suboemResponse.json()
        const suboemArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setSuboems(suboemArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchSuboems()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const token: string | null = localStorage.getItem('token')
      if (!token) {
        return
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${id}`,
        {
          method: 'DELETE',
          headers: {
            'x-api-key': token,
          },
        },
      )

      if (response.ok) {
        setSuboems((prevSuboems) => prevSuboems.filter((suboem) => suboem.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }

  const breadcrumbs = [
    <Typography
      key='3'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      SubOEM
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      {decodedPermissions.includes('manageSubOem') && (
        <Avatar
          component={Link}
          to='addsuboem'
          sx={{
            bgcolor: green[500],
            float: 'right',
            marginRight: 5,
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <AddIcon />
        </Avatar>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Oem Name</TableCell>
              {decodedPermissions.includes('manageSubOem') && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {suboems.map((suboem) => (
              <TableRow key={suboem.id}>
                <TableCell>{suboem.id}</TableCell>
                <TableCell>{suboem.name}</TableCell>
                <TableCell>{suboem.oem.oem_name}</TableCell>
                {decodedPermissions.includes('manageSubOem') && (
                  <TableCell>
                    <Button component={Link} to={`Updatesuboem/${suboem.id}`} state={suboem}>
                      <EditIcon />
                    </Button>
                    <Button onClick={() => handleDelete(suboem.id)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
