import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'
import { oemid, subOemid, decodedPermissions } from '../token'

export interface Regions {
  id: number
  name: string
  status: boolean
  suboem: string
}

export const Regions: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [regions, setRegions] = useState<Regions[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'REGIONS' })

    const fetchregions = async () => {
      try {
        const token: string | null = localStorage.getItem('token')

        if (!token) {
          return
        }

        const regionsResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/region`,

          {
            headers: {
              'x-api-key': token,
            },
          },
        )

        const jsonData = await regionsResponse.json()
        const regionsArray = Array.isArray(jsonData) ? jsonData : [jsonData]

        setRegions(regionsArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchregions()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/region/${id}`,

        {
          method: 'DELETE',
        },
      )

      if (response.ok) {
        setRegions((prevRegions) => prevRegions.filter((region) => region.id !== id))
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }
  const getStatusText = (status: boolean) => {
    return status ? 'Active' : 'Inactive'
  }

  const getStatusColor = (status: boolean) => {
    return status ? green[500] : red[500]
  }

  const getStatusBackgroundColor = (status: boolean) => {
    return status ? green[100] : red[100]
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Regions
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      {decodedPermissions.includes('manageRegion') && (
        <Avatar
          component={Link}
          to='addregions'
          sx={{
            bgcolor: green[500],
            float: 'right',
            marginRight: 5,
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <AddIcon />
        </Avatar>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Region Name</TableCell>
              <TableCell>Status</TableCell>
              {decodedPermissions.includes('manageRegion') && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {regions.map((region) => (
              <TableRow key={region.id}>
                <TableCell>{region.id}</TableCell>
                <TableCell>{region.name}</TableCell>
                <Typography
                  style={{
                    color: getStatusColor(region.status),
                    backgroundColor: getStatusBackgroundColor(region.status),
                    padding: '5px 10px',
                    borderRadius: '5px',
                  }}
                >
                  {getStatusText(region.status)}
                </Typography>

                <TableCell>
                  {decodedPermissions.includes('manageRegion') && (
                    <Button component={Link} to={`Updateregions/${region.id}`} state={region}>
                      <EditIcon />
                    </Button>
                  )}
                  {decodedPermissions.includes('manageRegion') && (
                    <Button onClick={() => handleDelete(region.id)}>
                      <DeleteIcon />
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default Regions
