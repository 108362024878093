import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Breadcrumbs,
} from '@mui/material'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { oemid } from '../token'

// interface Regions {
//   name: string
//   status: boolean
// }

const Updateregions: React.FC = () => {
  const location = useLocation()
  const region = location.state
  const { id } = useParams<{ id: string }>()

  const [name, setname] = useState(region.name)
  const [suboem, setsuboem] = useState(region.suboem)
  const [status, setstatus] = useState(region.status)
  const [nameError, setnameError] = useState(false)
  const [suboemError, setsuboemError] = useState(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)
    setsuboemError(false)

    if (name === '') {
      setnameError(true)
    }
    if (suboem === '') {
      setsuboemError(true)
    }

    if (name && suboem) {
      try {
        const token: string | null = localStorage.getItem('token')
        if (token === null) {
          return
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${suboem}/region/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify({ name, status }),
          },
        )

        if (response.ok) {
          /* empty */
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Regions
    </Typography>,
    <Typography key='2' color='text.primary'>
      Update Regions
    </Typography>,
  ]

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl>
            <Button variant='outlined' component={RouterLink} to='../'>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='Region name'
            label='Region Name'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            error={nameError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='Suboem'
            label='Suboem'
            variant='outlined'
            onChange={(e) => setsuboem(e.target.value)}
            value={suboem}
            error={suboemError}
          />
        </FormControl>

        <FormControlLabel
          value='start'
          control={<Checkbox checked={status} onChange={(e) => setstatus(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />

        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Update Region
          </Button>
        </FormControl>

        <FormControl>
          <Button className='clearButton' variant='contained' color='secondary'>
            Clear
          </Button>
        </FormControl>
      </Box>
    </Box>
  )
}

export default Updateregions
