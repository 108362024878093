import React, { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  Breadcrumbs,
  TextField,
  Typography,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { oemid } from '../token'

interface Regions {
  name: string
  status: boolean
}

const breadcrumbs = [
  <Typography
    key='1'
    color='inherit'
    component={RouterLink}
    to='../../'
    sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
  >
    Enterprise Architecture
  </Typography>,
  <Typography
    key='1'
    color='inherit'
    component={RouterLink}
    to='../'
    sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
  >
    Regions
  </Typography>,
  <Typography key='2' color='text.primary'>
    Add Regions
  </Typography>,
]

const Addregions: React.FC = () => {
  const [name, setname] = useState('')
  const [status, setstatus] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [nameError, setnameError] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [suboem, setsuboem] = useState('')

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)

    if (name === '') {
      setnameError(true)
      console.error('Name is required')
      return
    }

    if (name && status) {
      try {
        const RegionVar: Regions = {
          name: name,
          status: status,
        }
        const token: string | null = localStorage.getItem('token')
        if (token === null) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${suboem}/region`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify(RegionVar),
          },
        )

        console.log('API response:', response)

        if (response.ok) {
          setname('')
          setsuboem('')
          console.log('Form submitted successfully')
        } else {
          console.error('Error while making POST request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making POST request:', error)
      }
    }
  }

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl>
            <Button variant='outlined' component={RouterLink} to='../'>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='Region Name'
            label='Region Name'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            error={nameError}
            helperText={nameError ? 'Region Name is  required' : ''}
          ></TextField>
        </FormControl>

        <FormControl required variant='outlined' margin='normal'>
          <TextField
            id='suboem'
            label='SubOEM'
            variant='outlined'
            onChange={(e) => setsuboem(e.target.value)}
            value={suboem}
          />
        </FormControl>

        <FormControl required variant='outlined' margin='normal'>
          <FormControlLabel
            value='start'
            control={<Checkbox checked={status} onChange={(e) => setstatus(e.target.checked)} />}
            label='Is Active'
            labelPlacement='start'
          />
          <Button type='submit' variant='outlined'>
            Add Region
          </Button>
        </FormControl>

        <FormControl>
          <Button className='clearButton' variant='contained' color='secondary'>
            Clear
          </Button>
        </FormControl>
        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'OEM Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : error === 'At least one Diad is required'
                    ? 'At least one Diad is required'
                    : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}

export default Addregions
