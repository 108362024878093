import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'

import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'
import { oemid, subOemid } from '../token'

interface VehicleSegment {
  name: string
  logo_url: string
  is_active: boolean
  subOem: number
  created_on: number
}

const AddVehicleSegment: React.FC = () => {
  const [name, setName] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [subOem, setSubOem] = useState('')
  const [is_active, setIsActive] = useState(false)
  const [created_on, setCreatedOn] = useState('')

  const [nameError, setNameError] = useState(false)
  const [logoUrlError, setLogoUrlError] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const navigate = useNavigate()

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
    setError(null)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setNameError(false)
    setLogoUrlError(false)

    if (name === '') {
      setNameError(true)
      setError('Name is required')
    }
    if (logoUrl === '') {
      setLogoUrlError(true)
      setError('Logo URL is required')
    }

    if (name && logoUrl && subOem && created_on) {
      try {
        const vehicleSegment: VehicleSegment = {
          name,
          logo_url: logoUrl,
          is_active,
          subOem: parseInt(subOem),
          created_on: parseInt(created_on),
        }
        const token: string | null = localStorage.getItem('token')
        if (!token) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/subOem/${subOemid}/vehicleSegment`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify(vehicleSegment),
          },
        )

        if (response.ok) {
          setSnackbarOpen(true)
          setName('')
          setLogoUrl('')
          setCreatedOn('')
        } else {
          console.error('Error while making POST request:', response.status, response.statusText)
          setError('Error submitting the form. Please try again.')
        }
      } catch (error) {
        console.error('Error while making POST request:', error)
        setError('Error submitting the form. Please try again.')
      }
    }
  }

  const handleClear = () => {
    setName('')
    setLogoUrl('')
    setSubOem('')
    setCreatedOn('')
    setIsActive(false)
  }

  const handleBack = () => {
    navigate(-1)
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Vehicle Segment
    </Typography>,
    <Typography key='3' color='text.primary'>
      Add Vehicle Segment
    </Typography>,
  ]

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl sx={{ alignSelf: 'flex-end' }}>
            <Button variant='outlined' onClick={handleBack}>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label='Name'
            variant='outlined'
            onChange={(e) => setName(e.target.value)}
            value={name}
            error={nameError}
            helperText={nameError ? 'Name is required' : ''}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='logoUrl'
            label='Logo URL'
            variant='outlined'
            onChange={(e) => setLogoUrl(e.target.value)}
            value={logoUrl}
            error={logoUrlError}
            helperText={logoUrlError ? 'Logo URL is required' : ''}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='subOem'
            label='Sub OEM'
            variant='outlined'
            onChange={(e) => setSubOem(e.target.value)}
            value={subOem}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='createdOn'
            label='Created On'
            variant='outlined'
            onChange={(e) => setCreatedOn(e.target.value)}
            value={created_on}
          />
        </FormControl>

        <FormControlLabel
          value='start'
          control={<Checkbox checked={is_active} onChange={(e) => setIsActive(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />
        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Add Vehicle Segment
          </Button>
        </FormControl>
        <FormControl>
          <Button
            className='clearButton'
            variant='contained'
            color='secondary'
            onClick={handleClear}
          >
            Clear
          </Button>
        </FormControl>

        <Snackbar
          open={snackbarOpen || !!error}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={
            snackbarOpen
              ? 'Data updated successfully!'
              : error === 'Name is required'
                ? 'Name is required'
                : error === 'Logo URL is required'
                  ? 'Logo URL is required'
                  : 'Error submitting the form. Please try again.'
          }
        />
      </Box>
    </Box>
  )
}

export default AddVehicleSegment
