import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Breadcrumbs,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'

interface Oem {
  oem_name: string
  logo_url: string
  status: boolean
  diads: number[]
}

const Updateoem: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [oem_name, setoem_name] = useState('')
  const [logo_url, setLogoUrl] = useState('')
  const [diads, setDiads] = useState<number[]>([])
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  const [oem_nameError, setoem_nameError] = useState(false)
  const [logoUrlError, setLogoUrlError] = useState(false)
  const [isActive, setIsActive] = useState(Boolean)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/${id}`)
      .then((response) => response.json())
      .then((oemDetails: Oem) => {
        setoem_name(oemDetails.oem_name)
        setLogoUrl(oemDetails.logo_url)
        setDiads(oemDetails.diads)
        setIsActive(oemDetails.status)
      })
      .catch((error) => {
        console.error('Error while fetching details:', error)
      })
  }, [id])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setoem_nameError(false)
    setLogoUrlError(false)

    if (oem_name === '') {
      setoem_nameError(true)
    }
    if (logo_url === '') {
      setLogoUrlError(true)
    }
    if (oem_name && logo_url) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ oem_name, logo_url, diads, status: isActive }),
        })

        if (response.ok) {
          fetch(`${process.env.REACT_APP_API_BASE_URL}/oems/${id}`)
            .then((response) => response.json())
            .then((oemDetails: Oem) => {
              setoem_name(oemDetails.oem_name)
              setLogoUrl(oemDetails.logo_url)
              setDiads(oemDetails.diads)
              setIsActive(oemDetails.status)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })

          setSnackbarOpen(true)
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  const handleClear = () => {
    // setoem_name('')
    setLogoUrl('')
    setDiads([])
    setoem_nameError(false)
    setLogoUrlError(false)
  }
  const handleBack = () => {
    navigate('../')
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }
  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      OEM
    </Typography>,
    <Typography key='2' color='text.primary'>
      UPDATE OEM
    </Typography>,
  ]
  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl sx={{ alignSelf: 'flex-end' }}>
            <Button variant='outlined' onClick={handleBack}>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='OEM'
            label='OEM'
            variant='outlined'
            // onChange={(e) => setoem_name(e.target.value)}
            disabled
            sx={{ backgroundColor: '#f5f5f5' }}
            value={oem_name}
            error={oem_nameError}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='logo_url'
            label='Logo URL'
            variant='outlined'
            onChange={(e) => setLogoUrl(e.target.value)}
            value={logo_url}
            error={logoUrlError}
          ></TextField>
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='diads'
            label='Diads'
            variant='outlined'
            onChange={(e) => setDiads(e.target.value.split(',').map(Number))}
            value={diads.join(',')}
          />
        </FormControl>

        <FormControlLabel
          control={<Checkbox checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />

        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Update Oem
          </Button>
        </FormControl>

        <Button className='clearButton' variant='contained' color='secondary' onClick={handleClear}>
          Clear
        </Button>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message='Updated successfully!'
        />
      </Box>
    </Box>
  )
}

export default Updateoem
