import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { useDashboardContext } from '../sideDrawer'
import { Link as RouterLink } from 'react-router-dom'
import { oemid, subOemid } from '../token'

export interface VehicleSegment {
  id: number
  name: string
  logo_url: string
  is_active: boolean
  subOem: number
  created_on: number
}

export const VehicleSegment: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [vehiclesegment, setVehiclesegments] = useState<VehicleSegment[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'vehicle segment' })

    const fetchVehicleSegments = async () => {
      try {
        const token: string | null = localStorage.getItem('token')

        if (!token) {
          return
        }

        const oemsResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oem/${oemid}/subOem/4/vehicleSegment`,
          {
            headers: {
              'x-api-key': token,
            },
          },
        )

        const jsonData = await oemsResponse.json()
        const vehicleSegmentsArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setVehiclesegments(vehicleSegmentsArray)
        const oemNames = vehicleSegmentsArray.map((vehicleSegment) => vehicleSegment.name)

        localStorage.setItem('vehicleSegments', JSON.stringify(oemNames))
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchVehicleSegments()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const token: string | null = localStorage.getItem('token')
      if (!token) {
        return
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oem/${oemid}/subOem/${subOemid}/vehicleSegment/${id}`,
        {
          method: 'DELETE',
        },
      )

      if (response.ok) {
        setVehiclesegments((prevVehiclesegments) =>
          prevVehiclesegments.filter((vechilesegment) => vechilesegment.id !== id),
        )
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Vehicle Segment
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      {/* {decodedPermissions.includes('manageOEM') && ( */}
      <Avatar
        component={Link}
        to='addvehiclesegment'
        sx={{
          bgcolor: green[500],
          float: 'right',
          marginRight: 5,
          marginBottom: 5,
          marginTop: 5,
        }}
      >
        <AddIcon />
      </Avatar>
      {/* )} */}
      <TableContainer component={Paper}>
        <Table sx={{ marginTop: '12px' }}>
          <TableHead
            sx={{
              paddingTop: '1px',
              borderWidth: '10px',
              borderBottom: 'solid',
              borderColor: 'red',
              fontSize: '13px',
            }}
          >
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Logo URL</TableCell>
              {/* {decodedPermissions.includes('manageOem') && ( */}
              <TableCell sx={{ borderColor: 'red' }}>Actions</TableCell>
              {/* // )} */}
            </TableRow>
          </TableHead>
          <TableBody>
            {vehiclesegment.map((vehiclesegment) => (
              <TableRow key={vehiclesegment.id}>
                <TableCell>{vehiclesegment.id}</TableCell>
                <TableCell>{vehiclesegment.name}</TableCell>
                <TableCell>{vehiclesegment.logo_url}</TableCell>

                <TableCell>
                  <Button component={Link} to={`Updatevehiclesegment/${vehiclesegment.id}`}>
                    <EditIcon />
                  </Button>

                  <Button onClick={() => handleDelete(vehiclesegment.id)}>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
