import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'
import { oemid, subOemid } from '../token'

interface Submodel {
  id: number
  name: string
  description: string
  features: string
  trims_details: string
  color: string
  attachment: string
  vehicleModel: string
  is_active: boolean
}

export const Submodel: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [Submodel, setSubmodel] = useState<Submodel[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'Vechile Submodel' })
    const fetchVehiclesubmodel = async () => {
      try {
        const token: string | null = localStorage.getItem('token')

        if (!token) {
          return
        }

        const oemsResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/vehiclesegment/1/vehiclemodel/1/submode`,
          {
            headers: {
              'x-api-key': token,
            },
          },
        )

        const jsonData = await oemsResponse.json()
        const submodelArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setSubmodel(submodelArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchVehiclesubmodel()
  }, [updateContext])

  const breadcrumbs = [
    <Typography key='1' color='inherit'>
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      ECU
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      <Avatar
        component={Link}
        to='addecu'
        sx={{ bgcolor: green[500], float: 'right', marginRight: 5, marginBottom: 5, marginTop: 5 }}
      >
        <AddIcon />
      </Avatar>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Feature</TableCell>
              <TableCell>XYZ Details</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Attachment</TableCell>
              <TableCell>Created-on</TableCell>
              <TableCell>Is Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Submodel.map((submodel) => (
              <TableRow key={submodel.id}>
                <TableCell>{submodel.id}</TableCell>
                <TableCell>{submodel.name}</TableCell>
                <TableCell>{submodel.description}</TableCell>
                <TableCell>{submodel.features}</TableCell>
                <TableCell>{submodel.color}</TableCell>
                <TableCell>{submodel.trims_details}</TableCell>
                <TableCell>{submodel.attachment}</TableCell>
                <TableCell>{submodel.vehicleModel}</TableCell>
                <TableCell>
                  <Button component={Link} to='Updatesubmodel'>
                    <EditIcon />
                  </Button>

                  <Button component={Link} to='addsubmodel'>
                    <DeleteIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
