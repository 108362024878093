import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import * as React from 'react'
import Box from '@mui/material/Box'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import AssignmentIcon from '@mui/icons-material/Assignment'

const navigateTo = (path: string) => {
  // Perform navigation here
  console.log('Navigating to:', path)
  // Replace the above line with the actual navigation logic for your application
}

export const mainListItems = (
  <React.Fragment>
    <Box sx={{ height: 220, flexGrow: 1, maxWidth: 400 }}>
      <SimpleTreeView>
        <TreeItem itemId='grid' label='DashBoard' onClick={() => navigateTo('/dashboard')} />
        <TreeItem
          itemId='grid-community'
          label='EnterPrise Architecture'
          onClick={() => navigateTo('../dashboard')}
        />
        <TreeItem itemId='grid-pro' label='User Managment' onClick={() => navigateTo('/users')} />
        <TreeItem itemId='ascjkqnbcl' label='Device' />
        <TreeItem itemId='grid-premium' label='Dongal' onClick={() => navigateTo('/dongal')} />
      </SimpleTreeView>
    </Box>
  </React.Fragment>
)

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component='div' inset>
      Saved reports
    </ListSubheader>
    <ListItemButton onClick={() => navigateTo('/current-month')}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary='Current month' />
    </ListItemButton>
    <ListItemButton onClick={() => navigateTo('/last-quarter')}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary='Last quarter' />
    </ListItemButton>
    <ListItemButton onClick={() => navigateTo('/year-end-sale')}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary='Year-end sale' />
    </ListItemButton>
  </React.Fragment>
)
