import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDashboardContext } from '../sideDrawer'
import { oemid, subOemid, regionid, decodedPermissions } from '../token'
import { Link as RouterLink } from 'react-router-dom'
import Regions from '../regions/regions'

export interface Workshopgroup {
  id: number
  name: string
  region: Regions
  description: string
  status: boolean
}

export const Workshopgroup: React.FC = () => {
  const { updateContext } = useDashboardContext()
  const [Workshopgroups, setWorkshopgroups] = useState<Workshopgroup[]>([])

  useEffect(() => {
    updateContext({ pageTitle: 'DEALER PRINCIPLE' })

    const fetchworkshopgroups = async () => {
      try {
        const token: string | null = localStorage.getItem('token')
        if (!token) {
          return
        }

        const workshopgroupResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/region/${regionid}/workshopgroup`,
          {
            headers: {
              'x-api-key': token,
            },
          },
        )

        const jsonData = await workshopgroupResponse.json()
        const workshopgroupArray = Array.isArray(jsonData) ? jsonData : [jsonData]
        setWorkshopgroups(workshopgroupArray)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchworkshopgroups()
  }, [updateContext])

  const handleDelete = async (id: number) => {
    try {
      const token: string | null = localStorage.getItem('token')
      if (!token) {
        return
      }
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOemid}/region/${regionid}/workshopgroup/${id}`,
        {
          method: 'DELETE',
          headers: {
            'x-api-key': token,
          },
        },
      )

      if (response.ok) {
        setWorkshopgroups((prevWorkshopgroup) =>
          prevWorkshopgroup.filter((Workshopgroup: { id: number }) => Workshopgroup.id !== id),
        )
      } else {
        console.error('Error while making DELETE request:', response.status, response.statusText)
      }
    } catch (error) {
      console.error('Error while making DELETE request:', error)
    }
  }
  const getStatusText = (status: boolean) => {
    return status ? 'Active' : 'Inactive'
  }

  const getStatusColor = (status: boolean) => {
    return status ? green[500] : red[500]
  }

  const getStatusBackgroundColor = (status: boolean) => {
    return status ? green[100] : red[100]
  }
  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography key='2' color='text.primary'>
      Dealer Principle
    </Typography>,
  ]

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
        {breadcrumbs}
      </Breadcrumbs>
      {decodedPermissions.includes('manageDealerPrinciple') && (
        <Avatar
          component={Link}
          to='addworkshopgroup'
          sx={{
            bgcolor: green[500],
            float: 'right',
            marginRight: 5,
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <AddIcon />
        </Avatar>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Region</TableCell>
              {decodedPermissions.includes('manageDealerPrinciple') && (
                <TableCell>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {Workshopgroups.map((workshopgroup) => (
              <TableRow key={workshopgroup.id}>
                <TableCell>{workshopgroup.name}</TableCell>
                <TableCell>{workshopgroup.description}</TableCell>
                <TableCell>{workshopgroup.region.name}</TableCell>
                <TableCell>
                  <Typography
                    style={{
                      color: getStatusColor(workshopgroup.status),
                      backgroundColor: getStatusBackgroundColor(workshopgroup.status),
                      padding: '5px 10px',
                      borderRadius: '5px',
                    }}
                  >
                    {getStatusText(workshopgroup.status)}
                  </Typography>
                </TableCell>
                {decodedPermissions.includes('manageDealerPrinciple') && (
                  <TableCell>
                    <Button
                      component={Link}
                      to={`updateworkshopgroup/${workshopgroup.id}`}
                      state={workshopgroup}
                    >
                      <EditIcon />
                    </Button>
                    <Button onClick={() => handleDelete(workshopgroup.id)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
