import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material'

import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { oemid } from '../token'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'

export interface Workshop {
  name: string
  status: boolean
  country: string
  city: string
  state: string
  pincode: string
  address: string
  workshopgroup: string
}

const UpdateWorkshop: React.FC = () => {
  const location = useLocation()
  const workshop: Workshop = location.state
  const { id } = useParams<{ id: string }>()

  const [name, setname] = useState(workshop.name)
  const [country, setcountry] = useState(workshop.country)
  const [city, setcity] = useState(workshop.city)
  const [state, setstate] = useState(workshop.state)
  const [pincode, setpincode] = useState(workshop.pincode)
  const [address, setaddress] = useState(workshop.address)
  const [workshopgroup, setworkshopgroup] = useState(workshop.workshopgroup)
  const [status, setstatus] = useState(workshop.status)
  const [suboem, setsuboem] = useState('')
  const [region, setRegion] = useState('')

  const [nameError, setnameError] = useState(false)
  const [countryError, setcountryError] = useState(false)
  const [cityError, setcityError] = useState(false)
  const [stateError, setstateError] = useState(false)
  const [pincodeError, setpincodeError] = useState(false)
  const [addressError, setaddressError] = useState(false)
  const [workshopgroupError, setworkshopgroupError] = useState(false)
  const [suboemError, setsuboemError] = useState(false)
  const [regionError, setRegionError] = useState(false)

  const navigate = useNavigate()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setnameError(name === '')
    setcountryError(country === '')
    setcityError(city === '')
    setstateError(state === '')
    setpincodeError(pincode === '')
    setaddressError(address === '')
    setworkshopgroupError(workshopgroup === '')
    setsuboemError(suboem === '')
    setRegionError(region === '')

    if (
      name !== '' &&
      country !== '' &&
      city !== '' &&
      state !== '' &&
      pincode !== '' &&
      address !== '' &&
      workshopgroup !== '' &&
      suboem !== '' &&
      region !== ''
    ) {
      try {
        const token: string | null = localStorage.getItem('token')
        if (!token) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${suboem}/region/${region}/workshopgroup/${workshopgroup}/workshop/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify({
              name,
              country,
              city,
              state,
              pincode,
              address,
              status,
              workshopgroup,
            }),
          },
        )

        if (response.ok) {
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${suboem}/region/${region}/workshopgroup/${workshopgroup}/workshop/${id}`,
          )
            .then((response) => response.json())
            .then((workshopDetails: Workshop) => {
              setname(workshopDetails.name)
              setcountry(workshopDetails.country)
              setcity(workshopDetails.city)
              setstate(workshopDetails.state)
              setpincode(workshopDetails.pincode)
              setaddress(workshopDetails.address)
              setstatus(workshopDetails.status)
              setworkshopgroup(workshopDetails.workshopgroup)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  const handleBack = () => {
    navigate(-1)
  }
  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Dealer
    </Typography>,
    <Typography key='2' color='text.primary'>
      Update Dealer
    </Typography>,
  ]
  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Grid>
          <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid>
          <FormControl sx={{ alignSelf: 'flex-end' }}>
            <Button variant='outlined' onClick={handleBack}>
              Back
            </Button>
          </FormControl>
        </Grid>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'></Box>
      <Box
        display='grid'
        justifyContent='center'
        alignItems='center'
        component='form'
        sx={{
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit}
      >
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='name'
            label='Name'
            variant='outlined'
            onChange={(e) => setname(e.target.value)}
            value={name}
            error={nameError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='country'
            label='Country'
            variant='outlined'
            onChange={(e) => setcountry(e.target.value)}
            value={country}
            error={countryError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='city'
            label='City'
            variant='outlined'
            onChange={(e) => setcity(e.target.value)}
            value={city}
            error={cityError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='state'
            label='State'
            variant='outlined'
            onChange={(e) => setstate(e.target.value)}
            value={state}
            error={stateError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='pincode'
            label='Pincode'
            type='number'
            variant='outlined'
            onChange={(e) => setpincode(e.target.value)}
            value={pincode}
            error={pincodeError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='address'
            label='Address'
            variant='outlined'
            onChange={(e) => setaddress(e.target.value)}
            value={address}
            error={addressError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='workshopgroup'
            label='Workshop Group'
            variant='outlined'
            onChange={(e) => setworkshopgroup(e.target.value)}
            value={workshopgroup}
            error={workshopgroupError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='suboem'
            label='SubOem'
            type='number'
            variant='outlined'
            onChange={(e) => setsuboem(e.target.value)}
            value={suboem}
            error={suboemError}
          />
        </FormControl>
        <FormControl required variant='outlined' margin='normal'>
          <TextField
            required
            id='region'
            label='Region'
            type='number'
            variant='outlined'
            onChange={(e) => setRegion(e.target.value)}
            value={region}
            error={regionError}
          />
        </FormControl>
        <FormControlLabel
          value='start'
          control={<Checkbox checked={status} onChange={(e) => setstatus(e.target.checked)} />}
          label='Is Active'
          labelPlacement='start'
        />
        <FormControl required variant='outlined' margin='normal'>
          <Button type='submit' variant='outlined'>
            Update Workshop
          </Button>
        </FormControl>
        <FormControl>
          <Button className='clearButton' variant='contained' color='secondary'>
            Clear
          </Button>
        </FormControl>
      </Box>
    </Box>
  )
}

export default UpdateWorkshop
