import React, { useState } from 'react'
import { Box, Button, Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material'
// import axios, { AxiosResponse } from 'axios'
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom'

interface TestAPIResponse {
  success: string
}

interface Device {
  id: number
  device_mac_address: string
  device_type: string
  created_by: string

  details: string
  status: boolean
  user: number
}

const UpdateDevice: React.FC = () => {
  const location = useLocation()
  const device = location.state
  // const navigate = useNavigate()
  // const { id } = useParams<{ id: string }>()

  // Check if device is not null before accessing its properties
  const [device_mac_address, setdevice_mac_adderss] = useState(device?.device_mac_address || '')
  const [device_type, setdevice_type] = useState(device?.device_type || '')
  const [created_by, setcreated_by] = useState(device?.created_by || '')
  const [details, setdetails] = useState(device?.details || '')
  const [status, setstatus] = useState(device?.status || '')
  const [user, setuser] = useState(device?.user || '')
  const { id } = useParams<{ id: string }>()

  const [device_mac_addressError, setdevice_mac_adderssError] = useState(false)
  const [device_typeError, setdevice_typeError] = useState(false)
  const [created_byError, setcreated_byError] = useState(false)
  const [detailsError, setdetailsError] = useState(false)
  const [_statusError, setstatusError] = useState(false)
  const [userErorr, setuserError] = useState(false)

  console.log('Obtained device :', device)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setdevice_mac_adderssError(false)
    setdevice_typeError(false)
    setcreated_byError(false)
    setdetailsError(false)
    setstatusError(false)
    setuserError(false)

    if (device_mac_address === '') {
      setdevice_mac_adderssError(true)
    }
    if (device_type === '') {
      setdevice_typeError(true)
    }
    if (created_by === '') {
      setcreated_byError(true)
    }
    if (details === '') {
      setdetailsError(true)
    }
    if (user === '') {
      setuserError(true)
    }
    if (device_mac_address && device_type && created_by && details && status && user) {
      try {
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/device/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': token,
          },
          body: JSON.stringify({
            device_mac_address,
            device_type,
            created_by,
            details,
            status,
            user,
          }),
        })

        if (response.ok) {
          const responsData: TestAPIResponse = await response.json()
          console.log('RESP:', responsData)

          fetch(`${process.env.REACT_APP_API_BASE_URL}/device`)
            .then((response) => response.json())
            .then((DeviceDetails: Device) => {
              setdevice_mac_adderss(DeviceDetails.device_mac_address)
              setdevice_type(DeviceDetails.device_type)
              setcreated_by(DeviceDetails.created_by)
              setdetails(DeviceDetails.details)
              setuser(DeviceDetails.user)
              // setoem(SubOemDetails.oem)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })
          // setSnackbarOpen(true)
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  // const handleClear = () => {
  //   setdevice_mac_adderssError(false)
  // }

  return (
    <Box
      display='grid'
      justifyContent='center'
      alignItems='center'
      component='form'
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit}
    >
      <FormControl>
        <Button variant='outlined' component={RouterLink} to='../'>
          Back
        </Button>
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='Device'
          label='Device Mac Address'
          variant='outlined'
          onChange={(e) => setdevice_mac_adderss(e.target.value)}
          value={device_mac_address}
          error={device_mac_addressError}
        />
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='devicetype'
          label='Device Type'
          variant='outlined'
          onChange={(e) => setdevice_type(e.target.value)}
          value={device_type}
          error={device_typeError}
        />
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='created'
          label='Created By'
          variant='outlined'
          onChange={(e) => setcreated_by(e.target.value)}
          value={created_by}
          error={created_byError}
        />
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='Device Type'
          label='Device Type'
          variant='outlined'
          onChange={(e) => setdetails(e.target.value)}
          value={details}
          error={detailsError}
        />
      </FormControl>
      <FormControl required variant='outlined' margin='normal'>
        <TextField
          required
          id='User'
          label='User'
          variant='outlined'
          onChange={(e) => setuser(e.target.value)}
          value={user}
          error={userErorr}
        />
      </FormControl>
      <FormControlLabel
        value='start'
        control={<Checkbox checked={status} onChange={(e) => setstatus(e.target.checked)} />}
        label='Is Active'
        labelPlacement='start'
      />
      <FormControl required variant='outlined' margin='normal'>
        <Button type='submit' variant='outlined'>
          Update Device
        </Button>
      </FormControl>
      <FormControl>
        <Button className='clearButton' variant='contained' color='secondary'>
          Clear
        </Button>
      </FormControl>
    </Box>
  )
}

export default UpdateDevice
