import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Breadcrumbs,
  Typography,
} from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link as RouterLink } from 'react-router-dom'
import { oemid } from '../token'

interface WorkshopGroup {
  name: string
  description: string
  status: boolean
  region: string
}

const UpdateWorkshopgroup: React.FC = () => {
  const location = useLocation()
  const workshopgroup = location.state
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()

  const [name, setname] = useState(workshopgroup.name)
  const [description, setDescription] = useState(workshopgroup.description)
  const [region, setRegion] = useState(workshopgroup.region)
  const [subOem, setSubOem] = useState(workshopgroup.subOem)
  const [nameError, setnameError] = useState(false)
  const [descriptionError, setDescriptionError] = useState(false)
  const [regionError, setRegionError] = useState(false)
  const [subOemError, setSubOemError] = useState(false)
  const [status, setstatus] = useState(Boolean)

  console.log('Obtained workshopgroup: ', workshopgroup)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setnameError(false)
    setDescriptionError(false)
    setRegionError(false)
    setSubOemError(false)

    if (name === '') {
      setnameError(true)
    }
    if (description === '') {
      setDescriptionError(true)
    }
    if (region === '') {
      setRegionError(true)
    }
    if (subOem === '') {
      setSubOemError(true)
    }

    if (name && description && region && subOem) {
      try {
        const token: string | null = localStorage.getItem('token')

        if (token === null) {
          return
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOem}/region/${region}/workshopgroup/${id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': token,
            },
            body: JSON.stringify({ name, description, region, status }),
          },
        )

        if (response.ok) {
          fetch(
            `${process.env.REACT_APP_API_BASE_URL}/oems/${oemid}/suboem/${subOem}/region/${region}/workshopgroup/${id}`,
          )
            .then((response) => response.json())
            .then((WorkshopgroupDetails: WorkshopGroup) => {
              setname(WorkshopgroupDetails.name)
              setDescription(WorkshopgroupDetails.description)
              setRegion(WorkshopgroupDetails.region)
            })
            .catch((error) => {
              console.error('Error while fetching details:', error)
            })
        } else {
          console.error('Error while making PUT request:', response.status, response.statusText)
        }
      } catch (error) {
        console.error('Error while making PUT request:', error)
      }
    }
  }

  const handleBack = () => {
    navigate('../')
  }

  const breadcrumbs = [
    <Typography
      key='1'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Enterprise Architecture
    </Typography>,
    <Typography
      key='2'
      color='inherit'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      Dealer Principle
    </Typography>,
    <Typography
      key='2'
      color='text.primary'
      component={RouterLink}
      to='../'
      sx={{ textDecoration: 'none', '&:hover': { color: 'black', textDecoration: 'underline' } }}
    >
      ADD Dealer Principle
    </Typography>,
  ]

  return (
    <Box>
      <Box>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Grid>
            <Breadcrumbs separator={<NavigateNextIcon fontSize='small' />} aria-label='breadcrumb'>
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid>
            <FormControl sx={{ alignSelf: 'flex-end' }}>
              <Button variant='outlined' onClick={handleBack}>
                Back
              </Button>
            </FormControl>
          </Grid>
        </Box>
        <Box
          display='grid'
          justifyContent='center'
          alignItems='center'
          component='form'
          sx={{
            '& > :not(style)': { m: 1 },
          }}
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit}
        >
          <FormControl required variant='outlined' margin='normal'>
            <TextField
              required
              id='username'
              label='Workshop group'
              variant='outlined'
              onChange={(e) => setname(e.target.value)}
              value={name}
              error={nameError}
            />
          </FormControl>
          <FormControl required variant='outlined' margin='normal'>
            <TextField
              required
              id='description'
              label='Description'
              variant='outlined'
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              error={descriptionError}
            />
          </FormControl>
          <FormControl required variant='outlined' margin='normal'>
            <TextField
              required
              id='region'
              label='Region'
              variant='outlined'
              onChange={(e) => setRegion(e.target.value)}
              value={region}
              error={regionError}
            />
          </FormControl>
          <FormControl required variant='outlined' margin='normal'>
            <TextField
              required
              id='subOem'
              label='SubOEM'
              variant='outlined'
              onChange={(e) => setSubOem(e.target.value)}
              value={subOem}
              error={subOemError}
            />
          </FormControl>

          <FormControlLabel
            control={<Checkbox checked={status} onChange={(e) => setstatus(e.target.checked)} />}
            label='Is Active'
            labelPlacement='start'
          />

          <FormControl required variant='outlined' margin='normal'>
            <Button type='submit' variant='outlined'>
              Update Dealer Principle
            </Button>
          </FormControl>

          <FormControl>
            <Button className='clearButton' variant='contained' color='secondary'>
              Clear
            </Button>
          </FormControl>
        </Box>
      </Box>
    </Box>
  )
}

export default UpdateWorkshopgroup
